define(['app', '$window'], (app, $window) => {

  const storeDetailHeader = () => {
    const component = {};

    const _config = {
      selectors: {
        storeDetailHeaderTitle: '.storeDetailHeader_title',
        storeDetailHeaderSetButton: '.storeDetailHeader_setButton',
        previouslySelectedStoreStoreName: '.previouslySelectedStore_storeName'
      },
      attributes: {
        setAsMyStore: 'data-set-as-myStore',
        myStore: 'data-mystore',
      },
      lSKeys: {
        selectedStore: "selectedStore",
        prevSelectedStore: "previouslySelectedStore",
      },
      subscribeChannels: {
        storeDetailHeaderUpdate: 'storeDetailHeaderUpdate'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.storeDetailHeaderTitle = component.element.querySelector(component.config.selectors.storeDetailHeaderTitle);
      component.storeDetailHeaderSetButton = component.element.querySelector(component.config.selectors.storeDetailHeaderSetButton);
      component.previouslySelectedStoreStoreName = document.querySelector(component.config.selectors.previouslySelectedStoreStoreName);
      component.checkIsSelectedStore();
      component.addEventListeners();
      app.subscribe(component.config.subscribeChannels.storeDetailHeaderUpdate, component.checkIsSelectedStore);

      return component;
    };

    const _addEventListeners = () => {
      component.storeDetailHeaderSetButton.addEventListener('click', () => {
        const storeButton = document.querySelector('[data-store-name="' + component.storeDetailHeaderTitle.textContent + '"]');
        storeButton.click();
        component.checkIsSelectedStore();
      });
    };

    const _checkIsSelectedStore = () => {
      const currentlySelectedStore = component.getFromLS(component.config.lSKeys.selectedStore);
      if (currentlySelectedStore === component.storeDetailHeaderTitle.textContent) {
        component.storeDetailHeaderSetButton.innerText = component.storeDetailHeaderSetButton.getAttribute(component.config.attributes.myStore);
      } else {
        component.storeDetailHeaderSetButton.innerText = component.storeDetailHeaderSetButton.getAttribute(component.config.attributes.setAsMyStore);
      }
    };

    const _getFromLS = (localStorageKey) => {
      return $window.localStorage.getItem(localStorageKey);
    }

    component.config = _config;
    component.init = _init;
    component.addEventListeners = _addEventListeners;
    component.checkIsSelectedStore = _checkIsSelectedStore;
    component.getFromLS = _getFromLS;
    return component;
  };

  return storeDetailHeader;
});
